/* tslint:disable */
/* eslint-disable */
/**
 * Application API
 * Application API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Applicant
 */
export interface Applicant {
    /**
     * 
     * @type {number}
     * @memberof Applicant
     */
    'applicantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Applicant
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Applicant
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Applicant
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface Application
 */
export interface Application {
    /**
     * 
     * @type {number}
     * @memberof Application
     */
    'applicationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'applicationDate'?: string;
    /**
     * 
     * @type {Resume}
     * @memberof Application
     */
    'resume'?: Resume;
}
/**
 * 
 * @export
 * @interface JWTToken
 */
export interface JWTToken {
    /**
     * 
     * @type {string}
     * @memberof JWTToken
     */
    'id_token'?: string;
}
/**
 * 
 * @export
 * @interface LoginVM
 */
export interface LoginVM {
    /**
     * 
     * @type {string}
     * @memberof LoginVM
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof LoginVM
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginVM
     */
    'rememberMe'?: boolean;
}
/**
 * 
 * @export
 * @interface Resume
 */
export interface Resume {
    /**
     * 
     * @type {number}
     * @memberof Resume
     */
    'resumeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Resume
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resume
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resume
     */
    'submittedDate'?: string;
    /**
     * 
     * @type {Set<Application>}
     * @memberof Resume
     */
    'applications'?: Set<Application>;
    /**
     * 
     * @type {Applicant}
     * @memberof Resume
     */
    'applicant'?: Applicant;
}
/**
 * 
 * @export
 * @interface UserVM
 */
export interface UserVM {
    /**
     * 
     * @type {string}
     * @memberof UserVM
     */
    'login'?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof UserVM
     */
    'authorities'?: Set<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserVM
     */
    'activated'?: boolean;
}

/**
 * AccountResourceApi - axios parameter creator
 * @export
 */
export const AccountResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAuthenticated: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountResourceApi - functional programming interface
 * @export
 */
export const AccountResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isAuthenticated(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isAuthenticated(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountResourceApi - factory interface
 * @export
 */
export const AccountResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(options?: any): AxiosPromise<UserVM> {
            return localVarFp.getAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAuthenticated(options?: any): AxiosPromise<string> {
            return localVarFp.isAuthenticated(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountResourceApi - object-oriented interface
 * @export
 * @class AccountResourceApi
 * @extends {BaseAPI}
 */
export class AccountResourceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountResourceApi
     */
    public getAccount(options?: AxiosRequestConfig) {
        return AccountResourceApiFp(this.configuration).getAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountResourceApi
     */
    public isAuthenticated(options?: AxiosRequestConfig) {
        return AccountResourceApiFp(this.configuration).isAuthenticated(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicantResourceApi - axios parameter creator
 * @export
 */
export const ApplicantResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Applicant} applicant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplicant: async (applicant: Applicant, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicant' is not null or undefined
            assertParamExists('createApplicant', 'applicant', applicant)
            const localVarPath = `/api/applicants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicant: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApplicant', 'id', id)
            const localVarPath = `/api/applicants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllApplicants: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/applicants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicant: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApplicant', 'id', id)
            const localVarPath = `/api/applicants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} applicantId 
         * @param {Applicant} applicant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateApplicant: async (applicantId: number, applicant: Applicant, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicantId' is not null or undefined
            assertParamExists('partialUpdateApplicant', 'applicantId', applicantId)
            // verify required parameter 'applicant' is not null or undefined
            assertParamExists('partialUpdateApplicant', 'applicant', applicant)
            const localVarPath = `/api/applicants/{applicantId}`
                .replace(`{${"applicantId"}}`, encodeURIComponent(String(applicantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} applicantId 
         * @param {Applicant} applicant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicant: async (applicantId: number, applicant: Applicant, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicantId' is not null or undefined
            assertParamExists('updateApplicant', 'applicantId', applicantId)
            // verify required parameter 'applicant' is not null or undefined
            assertParamExists('updateApplicant', 'applicant', applicant)
            const localVarPath = `/api/applicants/{applicantId}`
                .replace(`{${"applicantId"}}`, encodeURIComponent(String(applicantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicantResourceApi - functional programming interface
 * @export
 */
export const ApplicantResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicantResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Applicant} applicant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApplicant(applicant: Applicant, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Applicant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApplicant(applicant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicant(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplicant(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllApplicants(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Applicant>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllApplicants(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicant(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Applicant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicant(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} applicantId 
         * @param {Applicant} applicant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateApplicant(applicantId: number, applicant: Applicant, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Applicant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateApplicant(applicantId, applicant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} applicantId 
         * @param {Applicant} applicant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicant(applicantId: number, applicant: Applicant, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Applicant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplicant(applicantId, applicant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicantResourceApi - factory interface
 * @export
 */
export const ApplicantResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicantResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {Applicant} applicant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplicant(applicant: Applicant, options?: any): AxiosPromise<Applicant> {
            return localVarFp.createApplicant(applicant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicant(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteApplicant(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllApplicants(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<Array<Applicant>> {
            return localVarFp.getAllApplicants(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicant(id: number, options?: any): AxiosPromise<Applicant> {
            return localVarFp.getApplicant(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} applicantId 
         * @param {Applicant} applicant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateApplicant(applicantId: number, applicant: Applicant, options?: any): AxiosPromise<Applicant> {
            return localVarFp.partialUpdateApplicant(applicantId, applicant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} applicantId 
         * @param {Applicant} applicant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicant(applicantId: number, applicant: Applicant, options?: any): AxiosPromise<Applicant> {
            return localVarFp.updateApplicant(applicantId, applicant, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicantResourceApi - object-oriented interface
 * @export
 * @class ApplicantResourceApi
 * @extends {BaseAPI}
 */
export class ApplicantResourceApi extends BaseAPI {
    /**
     * 
     * @param {Applicant} applicant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicantResourceApi
     */
    public createApplicant(applicant: Applicant, options?: AxiosRequestConfig) {
        return ApplicantResourceApiFp(this.configuration).createApplicant(applicant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicantResourceApi
     */
    public deleteApplicant(id: number, options?: AxiosRequestConfig) {
        return ApplicantResourceApiFp(this.configuration).deleteApplicant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicantResourceApi
     */
    public getAllApplicants(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ApplicantResourceApiFp(this.configuration).getAllApplicants(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicantResourceApi
     */
    public getApplicant(id: number, options?: AxiosRequestConfig) {
        return ApplicantResourceApiFp(this.configuration).getApplicant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} applicantId 
     * @param {Applicant} applicant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicantResourceApi
     */
    public partialUpdateApplicant(applicantId: number, applicant: Applicant, options?: AxiosRequestConfig) {
        return ApplicantResourceApiFp(this.configuration).partialUpdateApplicant(applicantId, applicant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} applicantId 
     * @param {Applicant} applicant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicantResourceApi
     */
    public updateApplicant(applicantId: number, applicant: Applicant, options?: AxiosRequestConfig) {
        return ApplicantResourceApiFp(this.configuration).updateApplicant(applicantId, applicant, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationResourceApi - axios parameter creator
 * @export
 */
export const ApplicationResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Application} application 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplication: async (application: Application, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'application' is not null or undefined
            assertParamExists('createApplication', 'application', application)
            const localVarPath = `/api/applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(application, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplication: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApplication', 'id', id)
            const localVarPath = `/api/applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllApplications: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApplication', 'id', id)
            const localVarPath = `/api/applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {Application} application 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateApplication: async (applicationId: number, application: Application, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('partialUpdateApplication', 'applicationId', applicationId)
            // verify required parameter 'application' is not null or undefined
            assertParamExists('partialUpdateApplication', 'application', application)
            const localVarPath = `/api/applications/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(application, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {Application} application 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplication: async (applicationId: number, application: Application, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('updateApplication', 'applicationId', applicationId)
            // verify required parameter 'application' is not null or undefined
            assertParamExists('updateApplication', 'application', application)
            const localVarPath = `/api/applications/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(application, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationResourceApi - functional programming interface
 * @export
 */
export const ApplicationResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Application} application 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApplication(application: Application, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApplication(application, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplication(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllApplications(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Application>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllApplications(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplication(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {Application} application 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateApplication(applicationId: number, application: Application, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateApplication(applicationId, application, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {Application} application 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplication(applicationId: number, application: Application, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplication(applicationId, application, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationResourceApi - factory interface
 * @export
 */
export const ApplicationResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {Application} application 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplication(application: Application, options?: any): AxiosPromise<Application> {
            return localVarFp.createApplication(application, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplication(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllApplications(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<Array<Application>> {
            return localVarFp.getAllApplications(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication(id: number, options?: any): AxiosPromise<Application> {
            return localVarFp.getApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {Application} application 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateApplication(applicationId: number, application: Application, options?: any): AxiosPromise<Application> {
            return localVarFp.partialUpdateApplication(applicationId, application, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {Application} application 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplication(applicationId: number, application: Application, options?: any): AxiosPromise<Application> {
            return localVarFp.updateApplication(applicationId, application, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationResourceApi - object-oriented interface
 * @export
 * @class ApplicationResourceApi
 * @extends {BaseAPI}
 */
export class ApplicationResourceApi extends BaseAPI {
    /**
     * 
     * @param {Application} application 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationResourceApi
     */
    public createApplication(application: Application, options?: AxiosRequestConfig) {
        return ApplicationResourceApiFp(this.configuration).createApplication(application, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationResourceApi
     */
    public deleteApplication(id: number, options?: AxiosRequestConfig) {
        return ApplicationResourceApiFp(this.configuration).deleteApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationResourceApi
     */
    public getAllApplications(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ApplicationResourceApiFp(this.configuration).getAllApplications(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationResourceApi
     */
    public getApplication(id: number, options?: AxiosRequestConfig) {
        return ApplicationResourceApiFp(this.configuration).getApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} applicationId 
     * @param {Application} application 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationResourceApi
     */
    public partialUpdateApplication(applicationId: number, application: Application, options?: AxiosRequestConfig) {
        return ApplicationResourceApiFp(this.configuration).partialUpdateApplication(applicationId, application, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} applicationId 
     * @param {Application} application 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationResourceApi
     */
    public updateApplication(applicationId: number, application: Application, options?: AxiosRequestConfig) {
        return ApplicationResourceApiFp(this.configuration).updateApplication(applicationId, application, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ResumeResourceApi - axios parameter creator
 * @export
 */
export const ResumeResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Resume} resume 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResume: async (resume: Resume, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resume' is not null or undefined
            assertParamExists('createResume', 'resume', resume)
            const localVarPath = `/api/resumes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resume, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResume: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteResume', 'id', id)
            const localVarPath = `/api/resumes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllResumes: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/resumes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResume: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getResume', 'id', id)
            const localVarPath = `/api/resumes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} resumeId 
         * @param {Resume} resume 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateResume: async (resumeId: number, resume: Resume, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resumeId' is not null or undefined
            assertParamExists('partialUpdateResume', 'resumeId', resumeId)
            // verify required parameter 'resume' is not null or undefined
            assertParamExists('partialUpdateResume', 'resume', resume)
            const localVarPath = `/api/resumes/{resumeId}`
                .replace(`{${"resumeId"}}`, encodeURIComponent(String(resumeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resume, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} resumeId 
         * @param {Resume} resume 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResume: async (resumeId: number, resume: Resume, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resumeId' is not null or undefined
            assertParamExists('updateResume', 'resumeId', resumeId)
            // verify required parameter 'resume' is not null or undefined
            assertParamExists('updateResume', 'resume', resume)
            const localVarPath = `/api/resumes/{resumeId}`
                .replace(`{${"resumeId"}}`, encodeURIComponent(String(resumeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resume, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResumeResourceApi - functional programming interface
 * @export
 */
export const ResumeResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResumeResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Resume} resume 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createResume(resume: Resume, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resume>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createResume(resume, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteResume(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteResume(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllResumes(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Resume>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllResumes(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResume(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resume>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResume(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} resumeId 
         * @param {Resume} resume 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateResume(resumeId: number, resume: Resume, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resume>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateResume(resumeId, resume, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} resumeId 
         * @param {Resume} resume 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateResume(resumeId: number, resume: Resume, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resume>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateResume(resumeId, resume, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ResumeResourceApi - factory interface
 * @export
 */
export const ResumeResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResumeResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {Resume} resume 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResume(resume: Resume, options?: any): AxiosPromise<Resume> {
            return localVarFp.createResume(resume, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResume(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteResume(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllResumes(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<Array<Resume>> {
            return localVarFp.getAllResumes(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResume(id: number, options?: any): AxiosPromise<Resume> {
            return localVarFp.getResume(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} resumeId 
         * @param {Resume} resume 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateResume(resumeId: number, resume: Resume, options?: any): AxiosPromise<Resume> {
            return localVarFp.partialUpdateResume(resumeId, resume, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} resumeId 
         * @param {Resume} resume 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResume(resumeId: number, resume: Resume, options?: any): AxiosPromise<Resume> {
            return localVarFp.updateResume(resumeId, resume, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ResumeResourceApi - object-oriented interface
 * @export
 * @class ResumeResourceApi
 * @extends {BaseAPI}
 */
export class ResumeResourceApi extends BaseAPI {
    /**
     * 
     * @param {Resume} resume 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResumeResourceApi
     */
    public createResume(resume: Resume, options?: AxiosRequestConfig) {
        return ResumeResourceApiFp(this.configuration).createResume(resume, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResumeResourceApi
     */
    public deleteResume(id: number, options?: AxiosRequestConfig) {
        return ResumeResourceApiFp(this.configuration).deleteResume(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResumeResourceApi
     */
    public getAllResumes(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return ResumeResourceApiFp(this.configuration).getAllResumes(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResumeResourceApi
     */
    public getResume(id: number, options?: AxiosRequestConfig) {
        return ResumeResourceApiFp(this.configuration).getResume(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} resumeId 
     * @param {Resume} resume 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResumeResourceApi
     */
    public partialUpdateResume(resumeId: number, resume: Resume, options?: AxiosRequestConfig) {
        return ResumeResourceApiFp(this.configuration).partialUpdateResume(resumeId, resume, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} resumeId 
     * @param {Resume} resume 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResumeResourceApi
     */
    public updateResume(resumeId: number, resume: Resume, options?: AxiosRequestConfig) {
        return ResumeResourceApiFp(this.configuration).updateResume(resumeId, resume, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserJwtControllerApi - axios parameter creator
 * @export
 */
export const UserJwtControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginVM} loginVM 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize: async (loginVM: LoginVM, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginVM' is not null or undefined
            assertParamExists('authorize', 'loginVM', loginVM)
            const localVarPath = `/api/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginVM, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserJwtControllerApi - functional programming interface
 * @export
 */
export const UserJwtControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserJwtControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LoginVM} loginVM 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorize(loginVM: LoginVM, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JWTToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorize(loginVM, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserJwtControllerApi - factory interface
 * @export
 */
export const UserJwtControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserJwtControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {LoginVM} loginVM 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize(loginVM: LoginVM, options?: any): AxiosPromise<JWTToken> {
            return localVarFp.authorize(loginVM, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserJwtControllerApi - object-oriented interface
 * @export
 * @class UserJwtControllerApi
 * @extends {BaseAPI}
 */
export class UserJwtControllerApi extends BaseAPI {
    /**
     * 
     * @param {LoginVM} loginVM 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserJwtControllerApi
     */
    public authorize(loginVM: LoginVM, options?: AxiosRequestConfig) {
        return UserJwtControllerApiFp(this.configuration).authorize(loginVM, options).then((request) => request(this.axios, this.basePath));
    }
}


